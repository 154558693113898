@import url('https://fonts.googleapis.com/css2?family=Press+Start+2P&family=Changa&family=Lato&family=Raleway&display=swap');

$login-btn-color: #F3C121;

$primary-background-color: #FFF;
$primary-background-color-font-color: #000;

$primary-color: #F26C33;
$primary-color-text-color: white;
$secondary-color: #104B79;
$tertiary-color: #ECEBE6;

$primary-font: 'Raleway';

@mixin verticalCenter {
    display: flex;
    justify-content: center;
    align-items: center;
}

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

body {
    background-color: white;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: absolute;
    align-items: center;
    width: 100%;
    font-size: 11pt;
    color: black;
    font-family: $primary-font;
}

li {
    list-style-type: none;
}
