#login-body{
    text-align: center;
    display: flex;
    flex-direction: column;
    margin: 0 10px;
}

.login {

    &-gaming{
        $primary-font: 'Changa';
        
        font-family: $primary-font;

        display: flex;
    }

    &-logo{
        margin-bottom: 20px;

        &__img{
            height: 100%;
            width: 100%;
            max-width: 464px;
        }
    }

    &-title{
        margin-bottom: 10px;
    }

    &-form{
        margin: 15px;
    }

    &-field{
        margin-bottom: 15px;
    }

    &__submit{
        background-color: $primary-color;
        border: 1px solid $primary-color;
        color: $primary-color-text-color;
        width: 100%;
        border-radius: 3px;
        padding: 6px 12px;
        cursor: pointer;
        margin-bottom: 15px;
    }
}
